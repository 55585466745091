import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { EMPTY_GROUP_ENTITY, GroupEntity } from "domain/entity/Group/GroupEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { GroupRepository } from "domain/repository/Group/GroupRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import _ from "lodash";
import { createGroupMaintenanceValidationSchema } from "presentation/constant/Group/GroupMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { GroupMaintenanceModel } from "presentation/model/Group/GroupMaintenanceModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";
interface GroupMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<GroupMaintenanceModel>> | ((value: SetStateAction<GroupMaintenanceModel>) => void),
    ],
    repo: GroupRepository,
    companyRepo: CompanyRepository,
    masterDataRepo: MasterDataRepository,
}
export const GroupMaintenanceVM = ({ dispatch, repo, companyRepo, masterDataRepo }: GroupMaintenanceVMProps) => {
    const [groupMainDispatch] = dispatch;

    const loadDropdownOption = async () => {
        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                groupMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyCodeDropdownOptions: companyCodeDropdownOptions,


                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.CONSORTIUM).then(
            consortiumCodes => {
                const consortiumCodeDropdownOptions = consortiumCodes?.map((opTml) => ({
                    dropdownLabel: opTml.code,
                    tagLabel: opTml.code,
                    value: opTml.code,
                })) ?? []

                groupMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        consortiumCodeDropdownOptions: [
                            ...consortiumCodeDropdownOptions
                        ]
                    },
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.SERVICE).then(
            serviceCodes => {
                const serviceCodeDropdownOptions = serviceCodes?.map((serviceCode) => ({
                    dropdownLabel: serviceCode.code,
                    tagLabel: serviceCode.code,
                    value: serviceCode.code,
                })) ?? []

                groupMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        serviceCodeDropdownOptions: [
                            ...serviceCodeDropdownOptions
                        ]
                    }
                }))
            }
        )
    }
    const onSearch = async () => {
        const entities = await repo.getEntities();
        groupMainDispatch(prevState => ({
            ...prevState,
            tableData: _.sortBy(_.map(entities, e => ({ ...e, groupMembers: e.members?.join(",") })), ["groupType", "groupCode"]),
            selectedRows: [],
            currentSelectedRow: EMPTY_GROUP_ENTITY,
            isBackMaster: false,
        }))
    }
    const updateSelectedRows = async (allRows: GroupEntity[], selecedRows: GroupEntity[]) => {
        groupMainDispatch(prevState => {

            return {
                ...prevState,
                tableData: [...allRows],
                selectedRows: selecedRows,
            }
        })
    }
    const onAdd = () => {
        groupMainDispatch(prevState => {

            return {
                ...prevState,
                isShowEditPanel: true,
                masterState: {
                    ...prevState.masterState,
                    isAdd: true,
                    isEditable: false,
                    isRead: false,
                    editingEntity: EMPTY_GROUP_ENTITY,
                }
            }
        })
    }
    const onEdit = (currentEntity: GroupEntity) => {
        groupMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: currentEntity,
                isShowEditPanel: true,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: false,
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }

    const onEditClick = (currentEntity: GroupEntity) => {
        groupMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: currentEntity,
                isShowEditPanel: true,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: true,
                    isRead: false,
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }

    const onReset = () => {
        groupMainDispatch(prevState => {
            const resetEntity = prevState.masterState.isAdd ? EMPTY_GROUP_ENTITY : prevState.currentSelectedRow;
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...resetEntity
                    },
                }
            }
        })
    }
    const onClose = () => {
        groupMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: EMPTY_GROUP_ENTITY,
                selectedRows: [],
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    editingEntity: EMPTY_GROUP_ENTITY,
                    allFormState: {}
                },
                isBackMaster: true,
                isShowEditPanel: false,
            }
        })
    }
    const onSaveClicked = () => {
        groupMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }
    const onSave = async (currentEntity: GroupEntity, isAdd: boolean) => {
        const valResult = isAdd ? await Validation(createGroupMaintenanceValidationSchema).ValidateFormOnly(currentEntity) : null;
        let validatedResult: { [x: string]: string } = {};
        if (valResult) {
            validatedResult = { ...valResult, warningMessage: 'Please input the missing value.' };

            groupMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },
                    }
                }
            });
            const res: ResponseEntity={
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        }
        if (isAdd) {
            return await repo.createEntity(currentEntity);
        } else {
            return await repo.updateEntity(currentEntity);
        }
    }
    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        groupMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }
    const onFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        if (fieldKey === 'groupCode') {
            val = val.toUpperCase().replace(/\s+/g, '');
        }
        groupMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val,
                    },
                    allFormState: {
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }
    return {
        loadDropdownOption: loadDropdownOption,
        updateSelectedRows: updateSelectedRows,
        onAdd: onAdd,
        onEdit: onEdit,
        onReset: onReset,
        onClose: onClose,
        onSearch: onSearch,
        onSaveClicked: onSaveClicked,
        onSave: onSave,
        onCheckboxChange: onCheckboxChange,
        onFieldChange: onFieldChange,
        onEditClick: onEditClick,
    }
} 
