import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { GroupRepoImpl } from "domain/repository/Group/GroupRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { useGroupMaintenanceTracked } from "presentation/store/Group/GroupMaintenanceProvider";
import { GroupMaintenanceVM } from "presentation/viewModel/Group/GroupMaintenanceVM";
import { useMemo } from "react";
export const useGroupMaintenanceVM = () => {
    const [, setGroupMaintenanceState] = useGroupMaintenanceTracked();
    const GroupMainVM = useMemo(() =>
        GroupMaintenanceVM({
            dispatch: [setGroupMaintenanceState],
            repo: GroupRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
        }), [setGroupMaintenanceState])
    return GroupMainVM
} 
