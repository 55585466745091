export const GroupMaintenanceRequiredFieldList: string[] = [
    'groupCode',
];
export const GroupMaintenanceConstant = {
    CATEGORY: "CODE",
    TITLE: "Group Maintenance",
    SUB_TITLE: "Group",
    GROUP_CODE: "Group Code",
    GROUP_DESC: 'Group Desc.',
    GROUP_TYPE: 'Group Type',
    GROUP_MEMBER: 'Group Member',
    ACTIVE_IND: 'Active Ind.',
} 
