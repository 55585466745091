import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { GroupEntity } from "domain/entity/Group/GroupEntity";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { GroupRepository } from "./GroupRepo";

export const GroupRepoImpl = (): GroupRepository => {
    const url = '/v1/masterGroup';
    const getEntities = async (): Promise<GroupEntity[]> => {
        return await axiosGetData(referenceAxiosInstance, url, []).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });

    }
    const getEntityByKey = async (key: string): Promise<GroupEntity> => {
        return await axiosGetData(referenceAxiosInstance, `${url}/${key}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }
    const createEntity = async (newData: GroupEntity): Promise<ResponseEntity> => {
        return await axiosPostData(referenceAxiosInstance, `${url}`, newData);
    }
    const updateEntity = async (updatedData: GroupEntity): Promise<ResponseEntity> => {
        return await axiosPutData(referenceAxiosInstance, `${url}`, updatedData);
    }
    const deleteEntity = async (key: string): Promise<ResponseEntity> => {
        return await axiosDeleteData(referenceAxiosInstance, `${url}`, key);
    }
    return {
        getEntities: getEntities,
        getEntityByKey: getEntityByKey,
        createEntity: createEntity,
        updateEntity: updateEntity,
        deleteEntity: deleteEntity,
    }
} 
