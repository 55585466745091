import { Permission } from "presentation/constant/ANAInfo/PermissionName"; 
import { GroupMaintenanceProvider } from "presentation/store/Group/GroupMaintenanceProvider"; 
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper"; 
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper"; 
import { GroupMasterView } from "presentation/view/section/Group/GroupMasterView"; 
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled"; 
const GroupMaintenanceCont = () => { 
    return <ANAInfoWrapper permission={Permission.GROUP_MAINTENANCE}> 
        <MessageBarWrapper> 
            <GroupMaintenanceProvider> 
                <GridStyles/> 
                <GroupMasterView/> 
            </GroupMaintenanceProvider> 
        </MessageBarWrapper> 
    </ANAInfoWrapper> 
} 
export default GroupMaintenanceCont; 
