import { MasterGroupAlias } from 'domain/entity/Group/GroupEntity';
import * as yup from 'yup';
export const commonGroupMaintenanceValidation: { [x: string]: any; } = {
  groupCode: yup.string().required("Missing"),
  groupType: yup.string().required("Missing").oneOf(MasterGroupAlias),
};
export const createGroupMaintenanceValidation: { [x: string]: any; } = {
  ...commonGroupMaintenanceValidation,
};
export const createGroupMaintenanceValidationSchema = yup.object().shape(createGroupMaintenanceValidation); 
