import { useGroupMaintenanceTracked } from "presentation/store/Group/GroupMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import GroupMaintenance from "./GroupMaintenance";
import { GroupMaintenanceFormPanel } from "./GroupMaintenanceFormPanel";
export const GroupMasterView: React.FC = () => {
    const [groupState] = useGroupMaintenanceTracked();
    const { isShowEditPanel } = groupState;
    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>
            <GroupMaintenance />
            {
                (isShowEditPanel &&
                    <SliderPanel
                        isOpen={false}
                        leftSectionWidth={"100%"}
                        rightSectionWidth={"0%"}
                        leftChildren={<GroupMaintenanceFormPanel />}
                    />
                )
            }
        </div>
    </>
} 
