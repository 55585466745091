import { EMPTY_GROUP_ENTITY, GroupEntity } from "domain/entity/Group/GroupEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";
export interface GroupDropdownOptions {
    companyCodeDropdownOptions: DropdownProps[],
    consortiumCodeDropdownOptions: DropdownProps[],
    serviceCodeDropdownOptions: DropdownProps[],
}
export interface MasterChangeState extends BaseViewChangeSate {
    editingEntity: GroupEntity,
}
export interface GroupMaintenanceModel {
    isLoading: boolean,
    tableData: GroupEntity[],
    currentSelectedRow: GroupEntity,
    selectedRows: GroupEntity[],
    dynamicOptions: GroupDropdownOptions,
    masterState: MasterChangeState,
    isBackMaster: boolean,
    isShowEditPanel: boolean,
}
export const EMPTY_MASTER_GROUPANY_MODEL: GroupMaintenanceModel = {
    isLoading: false,
    tableData: [],
    currentSelectedRow: { ...EMPTY_GROUP_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        companyCodeDropdownOptions: [],
        consortiumCodeDropdownOptions: [],
        serviceCodeDropdownOptions: []
    },
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingEntity: EMPTY_GROUP_ENTITY,
    },
    isBackMaster: false,
    isShowEditPanel: false,
} 
