import { masterGroupDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { GroupMaintenanceConstant } from "presentation/constant/Group/GroupMaintenanceConstant";
import { useGroupMaintenanceVM } from "presentation/hook/Group/useGroupMaintenanceVM";
import { useGroupMaintenanceTracked } from "presentation/store/Group/GroupMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useMemo } from "react";
import { FieldType, HPHCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import GroupEditPanelTitleBar from "./GroupEditPanelTitleBar";
export const GroupMaintenanceFormPanel = () => {
    const [groupState] = useGroupMaintenanceTracked();
    const groupVM = useGroupMaintenanceVM();
    const { currentSelectedRow, masterState, dynamicOptions } = groupState;
    const { editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState } = masterState;
    const SCREEN_CONSTANT = GroupMaintenanceConstant;

    const currentEntity = isRead ? currentSelectedRow : editingEntity;


    const members = useMemo(() => currentEntity.groupType === null ? [] : (currentEntity.groupType === 'Company Group' ? dynamicOptions.companyCodeDropdownOptions :
        (currentEntity.groupType === 'Consortium Group' ? dynamicOptions.consortiumCodeDropdownOptions : dynamicOptions.serviceCodeDropdownOptions)), [currentEntity.groupType, dynamicOptions.companyCodeDropdownOptions, dynamicOptions.consortiumCodeDropdownOptions, dynamicOptions.serviceCodeDropdownOptions]);

    const memoGroup = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentEntity?.groupCode || ''}
                fieldValue={currentEntity?.groupCode}
                fieldLabel={SCREEN_CONSTANT.GROUP_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'groupCode'}
                isShowMissingError={true}
                maxLength={5}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    groupVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isAdd, currentEntity?.groupCode, SCREEN_CONSTANT.GROUP_CODE, isSaveClicked, groupVM])
    const memoGroupDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "430px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={currentEntity?.groupDesc || ''}
                fieldValue={currentEntity?.groupDesc}
                fieldLabel={SCREEN_CONSTANT.GROUP_DESC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'groupDesc'}
                isShowMissingError={true}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    groupVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.groupDesc, SCREEN_CONSTANT.GROUP_DESC, isSaveClicked, groupVM])

    const memoGroupType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentEntity?.groupType || ''}
                fieldValue={currentEntity?.groupType || ''}
                fieldLabel={SCREEN_CONSTANT.GROUP_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'groupType'}
                isShowMissingError={true}
                maxLength={60}
                options={masterGroupDropdownOption}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    groupVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isAdd, currentEntity?.groupType, SCREEN_CONSTANT.GROUP_TYPE, isSaveClicked, groupVM])

    const memoGroupMember = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "430px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={''}
                fieldValue={currentEntity?.members ?? []}
                fieldLabel={SCREEN_CONSTANT.GROUP_MEMBER}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'members'}
                isMultipleDropDown={true}
                isShowMissingError={true}
                options={members}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    groupVM.onFieldChange(fieldKey, fieldValue && fieldValue as string[], fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.members, SCREEN_CONSTANT.GROUP_MEMBER, isSaveClicked, members, groupVM])


    const memoActiveInd = useMemo(() =>
        <>
            <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>

                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd === 'Y'}
                    disabled={!(isEditable || isAdd)}
                    onChange={(e) => groupVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
        </>
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isEditable, isAdd, groupVM])

    const memoEditPanelTitleBar = useMemo(() => {
        return <GroupEditPanelTitleBar />
    }, [])
    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}
        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <div className={'im-flex-row'}>
                        <div className={'flex-row-item-stretch'}>
                            <SidebarTitle>{!isAdd ? currentEntity.groupCode : "NEW GROUP"}</SidebarTitle>
                            {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </div>
                    </div>

                    <CriteriaItemContainer>
                        {memoGroupType}
                        {memoGroup}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoGroupDesc}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoGroupMember}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoActiveInd}
                    </CriteriaItemContainer>
                </div>
            </div>
        </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
} 
