import { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { GroupMaintenanceConstant } from "./GroupMaintenanceConstant";
const SCREEN_CONSTANT = GroupMaintenanceConstant;
export const INITIAL_GROUP_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.GROUP_TYPE,
        field: "groupType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
        //pinned: "left", 
        //checkboxSelection: true, 
        //headerCheckboxSelection: true, 

    },
    {
        headerName: SCREEN_CONSTANT.GROUP_CODE,
        field: "groupCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.GROUP_DESC,
        field: "groupDesc",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.GROUP_MEMBER,
        field: "groupMembers",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
    },
].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, [], [], [], cellRenderers);
}); 
