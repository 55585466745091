import { GroupMaintenanceConstant } from "presentation/constant/Group/GroupMaintenanceConstant";
import { useGroupMaintenanceVM } from "presentation/hook/Group/useGroupMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useGroupMaintenanceTracked } from "presentation/store/Group/GroupMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
const GroupEditPanelTitleBar: React.FC = () => {
    const [groupState] = useGroupMaintenanceTracked();
    const groupVM = useGroupMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const { currentSelectedRow, masterState } = groupState;
    const { editingEntity, isAdd, isEditable, isRead } = masterState;

    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead ? currentSelectedRow : editingEntity;
    const handleReset = useCallback(async () => {
        groupVM.onReset();
    }, [groupVM]);
    const handleEdit = useCallback(() => {
        groupVM.onEditClick(currentEntity);
    }, [currentEntity, groupVM])
    const handleSave = useCallback(async () => {
        setIsLoading(true);
        try {
            groupVM.onSaveClicked();
            const res = await groupVM.onSave(currentEntity, isAdd);
            if (!!!res || !res.success) {
                messageBarVM.showWarining(res?.data ?? 'Save data failed.');
            } else {
                groupVM.onClose();
                setIsLoading(true);
            }
        } catch (error) {
            messageBarVM.showError('Save data failed.');
        } finally {
            setIsLoading(false)
        }

    }, [currentEntity, isAdd, messageBarVM, groupVM]);
    return <Sidebarheader style={{ width: '100%' }}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{ title: GroupMaintenanceConstant.CATEGORY },
            { title: GroupMaintenanceConstant.TITLE }]} onCurrentClick={() => { }}>
            </HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {!(isAdd || isEditable) && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />}
            {(isAdd || isEditable) && <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />}
            {<IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={groupVM.onClose} />}
            {(isAdd || isEditable) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
        </StyledAction>
    </Sidebarheader>
}
export default memo(GroupEditPanelTitleBar); 
